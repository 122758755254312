import { Card } from "antd";

export default function BitcoinPaymentIntro() {
  return (
    <Card title="Bitcoin Payment Tutorial">
      <details>
        <summary>Trust Wallet Tutorial</summary>
        <p>
          Step1: Download and install Trust Wallet from{" "}
          <a
            href="https://trustwallet.com/download"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://trustwallet.com
          </a>
        </p>
        <p>
          Step2: Create a new wallet
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/jBCXf8yylQA?si=2JdTueVE-8hXsOoP&amp;start=30"
            title="Bitcoin Payment Tutorial"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </p>
        <p>
          Step3: Purchase Bitcoin
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/jBCXf8yylQA?si=FYQhQQBqD2dtHU-T&amp;start=109"
            title="Purchase Bitcoin"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </p>
        <p>Step4: Send Bitoin to our address</p>
        <p>
          You can use the Trust wallet to press the send button or scan our
          qrcode to send bitcoin
        </p>
      </details>
    </Card>
  );
}
