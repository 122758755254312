import React from "react";
import { Steps, Card, Typography, Divider, Button, message } from "antd";

const { Step } = Steps;
const { Text } = Typography;

const Express = ({ tracking_info, tracking_number }) => {
  const deliverySteps = tracking_info;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(tracking_number)
      .then(() => {
        message.success("Copied to clipboard");
      })
      .catch(() => {
        message.error("Failed to copy");
      });
  };

  return (
    <Card title="Express Tracking" style={{ maxWidth: 1200, margin: "0 auto" }}>
      <p>
        Your Tracking Number: {tracking_number}
        <Button type="link" onClick={copyToClipboard} style={{ marginLeft: 8 }}>
          Copy
        </Button>
      </p>
      <p>
        1. If your tracking number start with ACI pls go to check{" "}
        <a href="https://www.acilogistix.com/track-my-parcel/">ACI</a>
      </p>
      <Divider />
      <Steps direction="vertical" current={2}>
        {deliverySteps.map((step, index) => (
          <Step
            key={index}
            description={
              <>
                <Text type="secondary">{step.description}</Text>
                <br />
              </>
            }
          />
        ))}
      </Steps>
    </Card>
  );
};

export default Express;
